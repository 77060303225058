<template>

</template>

<script>
	export default{
		name:'titles',
		props:{
			nameT:{
				type:String,
			}
		},
	}
</script>

<style scoped="scoped">
	#titles{
		margin-top: 10px;
	}
</style>
