<template>
	<div id="add" v-if="iscompile">
		<div class="alert">
			<div class="tile_liubai">
				<div class="title_add">
					<div class="add-tit">编辑部门</div>
					<div class="addX" @click="close('formName')">x</div>
				</div>
			</div>

			<div class="add_content">
				<div class="formtow">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item label="角色名称" prop="name">
							<el-input v-model="ruleForm.name" :placeholder="name"></el-input>
						</el-form-item>
						<el-form-item label="角色描述" prop="desc">
							<el-input type="textarea" :rows="10" v-model="ruleForm.desc" :placeholder="content"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'compile',
		data() {
			return {
				ruleForm: {
					name: '',
					desc: ''
				},
				rules: {
					name: [{
							required: true,
							message: '请输入角色名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 5,
							message: '长度在 3 到 5 个字符',
							trigger: 'blur'
						}
					],
					desc: [{
						required: true,
						message: '请填写角色描述',
						trigger: 'blur'
					}]
				}
			}

		},
		props: {
			iscompile: {
				type: Boolean,
			},
			content: {
				type: String,
			},
			name: {
				type: String,
			},
			ids: {
				type: Number,
			}
		},
		methods: {
			close(formName){
				this.$emit('compX','ok');
				this.$refs['ruleForm'].resetFields();
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let token = this.$storage.getLocal('token');
						let name = this.ruleForm.name;
						let content = this.ruleForm.desc;
						this.$request.roleUpInfo({
							name,
							content,
							token,
							id:this.ids
						}).then(res => {
							if (res.code == 0) {
								this.$emit("del", 'ok');
								this.isShow = false;
								this.$notify({
									title: '成功',
									message: '修改成功',
									type: 'success'
								});
								this.$emit('compX','ok');
								this.$emit('refresh','ok');
								this.$refs['ruleForm'].resetFields();
							}else{
								this.$emit("del", 'ok');
								this.isShow = false;
								this.$emit('compX','ok');
								this.$emit('refresh','ok');
								this.$refs['ruleForm'].resetFields();
								this.$notify({
									title: '失败',
									message: '修改失败',
									type: 'warning'
								});
							}
						})
					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style scoped="scoped">
	.alert {
		width: 500px;
		height: 500px;
		position: absolute;
		top: -10%;
		left: 50%;
		transform: translate(-50%, 50%);
		background-color: #ffffff;
		-moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.2);
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.2);
		z-index: 2;
	}

	#add {
		width: 100%;
		font-size: 20px;
		/* width: 50px; */
		/* margin-bottom: 20px; */
		/* border-top: 1px solid #333333; */
	}

	.addcon {
		width: 80%;
		display: flex;
		justify-content: space-between;
		height: 30px;
		/* line-height: 50px; */
		font-size: 15px;
		margin: 10px 0;
	}

	.openadd {
		width: 80px;
		height: 30px;
		border: 1px solid #b3b3b3;
		line-height: 30px;
		background-color: #FFFFFF;
	}

	.bai {
		background-color: #FFFFFF;
		height: 10px;
		width: 100%;
	}


	.tile_liubai {
		width: 100%;
		background: #F2F2F2;
	}

	.title_add {
		width: 95%;
		height: 50px;
		font-size: 20px;
		font-weight: 800;
		display: flex;
		justify-content: space-between;
		background: #F2F2F2;
		color: #666666;
		line-height: 50px;
		margin: 0 auto;
	}

	.add_content {
		width: 90%;
		margin: 50px auto;
	}

	.el-textarea textarea {
		height: 220px;
	}
</style>
