<template>
  <div id="contents">
	  <Card style='margin-left: 200px;margin-top: -20px;'>
    <div class="cont">
		
      <table>
        <tr>
          <td class="titNa" v-for="(item, index) in titles" :key="index">
            {{ item }}
          </td>
        </tr>

        <tr v-for="(item2, index2) in contentC" :key="index2" v-if="flag == 0">
          <td>{{ item2.role_name }}</td>
          <td>{{ item2.content }}</td>
          <td>{{ item2.ctime }}</td>
          <td @click="start(item2.role_id, item2.is_start)">
            <el-switch
              v-model="item2.is_start"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </td>
          <td class="oper">
            <div
              class="stte"
              @click="jurisdiction(item2.role_id, item2.role_name)"
            >
              权限设置
            </div>
            <div
              class="com"
              @click="compile(item2.role_name, item2.content, item2.role_id)"
            >
              编辑
            </div>
            <div class="del" @click="del(item2.role_id)">删除</div>
          </td>
        </tr>

        <tr v-for="(item2, index2) in contentC" :key="index2" v-if="flag == 1">
          <td>{{ item2.admin_name }}</td>
          <td>{{ item2.admin_user == null ? "暂无" : item2.admin_user }}</td>
          <td>{{ item2.admin_tel == "" ? "暂无" : item2.admin_tel }}</td>
          <td>{{ item2.admin_email == null ? "暂无" : item2.admin_email }}</td>
          <td>{{ item2.admin_time }}</td>
          <td>
            {{ item2.last_logon_time == null ? "暂无" : item2.last_logon_time }}
          </td>
          <td @click="start2(item2.admin_id, item2.is_start)">
            <el-switch
              v-model="item2.is_start"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </td>
          <td class="oper">
            <div class="com" @click="compiles(item2.admin_id)">编辑</div>
            <div class="del" @click="open(item2.admin_id)">删除</div>
          </td>
        </tr>

        <tr v-for="(item2, index2) in contentC" :key="index2" v-if="flag == 2">
          <td>{{ item2.admin_name }}</td>
          <td>{{ item2.admin_name }}</td>
          <td>{{ item2.admin_user == null ? "暂无" : item2.admin_user }}</td>
          <td>{{ item2.admin_tel == "" ? "暂无" : item2.admin_tel }}</td>
          <td>{{ item2.admin_email == null ? "暂无" : item2.admin_email }}</td>
          <td>{{ item2.admin_time }}</td>
          <td>
            {{ item2.last_logon_time == null ? "暂无" : item2.last_logon_time }}
          </td>
          <td @click="start2(item2.admin_id, item2.is_start)">
            <el-switch
              v-model="item2.is_start"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </td>
          <td class="oper">
            <div class="com" @click="compiles(item2.admin_id)">编辑</div>
            <div class="del" @click="open(item2.admin_id)">删除</div>
          </td>
        </tr>
      </table>
    </div>
    <compile
      :iscompile="isshow"
      @compX="compileX"
      :content="content"
      :name="name"
      :ids="ids"
      @refresh="btnrefresh"
    ></compile>
	</Card>
  </div>
</template>

<script>
import compile from "./Compile.vue";
export default {
  name: "contents",
  props: {
    contentC: {
      type: Array,
    },
    titles: {
      type: Array,
    },
    flag: {
      type: Number,
    },
  },
  data() {
    return {
      math: {
        item1: true,
        item2: false,
      },
      isshow: false,
      name: null,
      content: null,
      ids: null,
    };
  },
  created() {
    console.log(this.flag);
  },
  components: {
    compile,
  },
  methods: {
    compiles(id) {
      this.$router.push({
        path: "/addUser",
        query: {
          id: id,
        },
      });
      // let token = this.$storage.getLocal('token');
      // this.$request.updateAdminSel({token,id}).then(res=>{

      // 	console.log(res)
      // })
    },
    compileX() {
      this.isshow = false;
    },
    compile(name, content, ids) {
      this.isshow = true;
      this.name = name;
      this.content = content;
      this.ids = ids;
    },
    del(id) {
      let token = this.$storage.getLocal("token");
      this.$request
        .roleDel({
          id,
          token,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$emit("del", "ok");
          } else {
            this.$emit("del", "ok");
            this.$notify({
              title: "失败",
              message: "删除失败",
              type: "warning",
            });
          }
        });
    },
    btnrefresh() {
      this.$emit("del", "ok");
    },
    start(id, start) {
      let token = this.$storage.getLocal("token");
      this.$request
        .roleUpIsStart({
          id,
          token,
          start: start ? 1 : 2,
        })
        .then((res) => {
          if (res.code == 0) {
          }
        });
    },
    start2(id, start) {
      let token = this.$storage.getLocal("token");
      this.$request
        .adminUpIsStart({
          token,
          id,
          start: start ? 1 : 2,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "成功",
              message: "成功",
              type: "success",
            });
          } else {
            this.$notify({
              title: "警告",
              message: "失败",
              type: "warning",
            });
          }
        });
    },
    open(id) {
      this.$confirm("此操作将永久删除该用户,不可恢复,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let token = this.$storage.getLocal("token");
          console.log(id);
          this.$request
            .delAdmin({
              id,
              token,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$emit("del", "ok");
              } else {
                this.$emit("del", "ok");
                this.$notify({
                  title: "失败",
                  message: "删除失败",
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    jurisdiction(id, name) {
      this.$router.replace(
        {
          path: "/jurisdiction?id=" + id + "&name=" + name,
        },
        function () {},
        function () {}
      );
    },
  },
};
</script>

<style scoped="scoped">
table {
  width: 100%;
}

td {
  background-color: #ffffff;
  font-size: 15px;
  height: 30px;
  /* width: 15%; */
}

tr .titNa {
  background-color: #f9fafc;
  font-size: 15px;
  font-weight: 800;
}

.oper {
  display: flex;
  justify-content: space-around;
  color: #42b983;
}
/* #contents {
  height: 900px;
} */
#contents{
	height: 800px;
		width: 95%;
		margin-left: 2.5%;
		margin-top: 30px;
	}
.com,
.stte
.del {
  cursor: pointer;
}
</style>
